<template>
  <header class="header" id="header">
    <nav class="nav container">
      <a href="#" class="nav__logo"> NunoLcm </a>
      <div class="nav__menu" :class="{ showMenu: isActive }" id="nav-menu">
        <ul class="nav__list grid">
          <li @click="addClass" class="nav__item">
            <a href="#home" class="nav__link" :class="{ showMenu: isActive }">
              <i class="ri-home-5-line"></i>
              Home
            </a>
          </li>
          <li @click="addClass" class="nav__item">
            <a href="#skills" class="nav__link">
              <i class="ri-trophy-line"></i>
              Skills
            </a>
          </li>
          <li @click="addClass" class="nav__item">
            <a href="#qualification" class="nav__link">
              <i class="ri-book-open-line"></i>
              Qualifications
            </a>
          </li>
          <li @click="addClass" class="nav__item">
            <a href="#projects" class="nav__link">
              <i class="ri-image-line"></i>
              Projects
            </a>
          </li>
          <li @click="addClass" class="nav__item">
            <a href="#contact" class="nav__link">
              <i class="ri-chat-3-line"></i>
              Contact
            </a>
          </li>
        </ul>
        <!-- Close button -->
        <div @click="addClass" class="nav__close" id="nav-close">
          <i class="ri-close-line"></i>
        </div>
      </div>
      <div class="nav__buttons">
        <!-- <i :class="`${icon} change-theme`" @click="toggleTheme"></i> -->
        <div @click="addClass" class="nav__toggle" id="nav-toggle">
          <i class="ri-menu-4-line"></i>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
// import { mapState } from "vuex";
export default {
  data() {
    return {
      isActive: false,
      bgHeader: false,
      // icon: "ri-moon-line",
      // darkTheme: false,
    };
  },
  methods: {
    addClass() {
      console.log(this.isActive);
      this.isActive = !this.isActive;
    },
    // toggleTheme() {
    //   this.icon = this.icon === "ri-moon-line" ? "ri-sun-line" : "ri-moon-line";
    //   this.darkTheme = !this.darkTheme;
    //   document.body.classList.toggle("dark-theme", this.darkTheme);
    // },
  },
  // computed: {
  //   ...mapState(["headerClass"]),
  // },
};
</script>

<style lang="scss"></style>
