<template>
  <section class="skills section" id="skills">
    <h2 class="section__title">Skills</h2>
    <span class="section__subtitle">My favorite skills</span>
    <div class="skills__container container grid section__border">
      <!-- SKILLS 1 -->
      <div class="skills__content">
        <h3 class="skills__title">
          <i class="ri-braces-line"></i>Front-End Developer
        </h3>
        <div class="skills__info">
          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/html-1.svg" alt="" />
            </div>
            <h3 class="skills__name">HTML</h3>
            <span class="skills__subtitle">Advanced</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/css-3.svg" alt="" />
            </div>
            <h3 class="skills__name">CSS</h3>
            <span class="skills__subtitle">Advanced</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/logo-javascript.svg" alt="" />
            </div>
            <h3 class="skills__name">JavaScript</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/vue.webp" alt="" />
            </div>
            <h3 class="skills__name">VUE</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/bootstrap.webp" alt="" />
            </div>
            <h3 class="skills__name">Bootstrap</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/sass.webp" alt="" />
            </div>

            <h3 class="skills__name">Sass</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/git-icon.svg" alt="" />
            </div>

            <h3 class="skills__name">Git</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>
        </div>
      </div>
      <!-- SKILLS 2 -->
      <div class="skills__content">
        <h3 class="skills__title">
          <i class="ri-braces-line"></i>Back-End Developer
        </h3>
        <div class="skills__info">
          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/php.svg" alt="" />
            </div>

            <h3 class="skills__name">PHP</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/nodejs-1.svg" alt="" />
            </div>

            <h3 class="skills__name">Node JS</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/laravel-2.svg" alt="" />
            </div>

            <h3 class="skills__name">Laravel</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/mysql-logo.svg" alt="" />
            </div>

            <h3 class="skills__name">MySql</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>

          <div class="skills__data">
            <div class="skills__blob">
              <img src="../assets/img/mongodb.svg" alt="" />
            </div>

            <h3 class="skills__name">MongoDB</h3>
            <span class="skills__subtitle">Intermediate</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>
