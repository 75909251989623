<template>
  <section class="home section" id="home">
    <div class="home__container container grid section__border">
      <transition name="slide-fade">
        <div class="home__data grid">
          <h1 class="home__title">
            Hi, I'm Nuno <br />
            Full Stack developer <br />
            Based in Lauwe, Belgium
          </h1>
          <div class="home__blob grid">
            <div class="home__perfil">
              <img src="../assets/img/myEmojiBack.png" alt="" />
            </div>
            <img
              src="../assets/img/shape-wawes.svg"
              alt=""
              class="home__shape-wawes"
            />
            <img
              src="../assets/img/shape-circle.svg"
              alt=""
              class="home__shape-circle"
            />
          </div>
          <ul class="home__social">
            <a
              href="https://www.linkedin.com/in/nuno-lcm/"
              target="_blank"
              class="home__social-link"
            >
              <i class="ri-linkedin-box-line"></i>
            </a>
            <a
              href="https://github.com/NlcmarquesDev"
              target="_blank"
              class="home__social-link"
            >
              <i class="ri-github-line"></i>
            </a>
            <a
              href="https://open.spotify.com/playlist/1Brri741BM6JycppajFprj"
              target="_blank"
              class="home__social-link"
            >
              <i class="ri-spotify-line"></i>
            </a>
          </ul>
        </div>
      </transition>

      <!-- HOME INFO 1 -->
      <div class="home__info">
        <div>
          <h3 class="home__info-title">BIOGRAPHY</h3>
          <p class="home__info-description">
            Hi! I'm Nuno, a Full stack developer based in lauwe. I'm {{ age }}
            years young, husband and father, adventure lover, coffee addict..
          </p>
        </div>
        <div>
          <h3 class="home__info-title">CONTACT</h3>
          <p class="home__info-description">
            Lauwe, Kortrijk - Belgium<br />
            nunolcmarque@gmail.com<br />
            +32 468 43 18 23
          </p>
        </div>
        <div>
          <h3 class="home__info-title">SERVICES</h3>
          <p class="home__info-description">
            Front-end <br />
            Back-end <br />
          </p>
        </div>
      </div>
      <!-- HOME INFO 2 -->
      <div class="home__info">
        <div>
          <h3 class="home__info-title">PROFESSIONAL EXPERIENCE</h3>
          <p class="home__info-number">+01</p>
        </div>
        <div>
          <h3 class="home__info-title">ACADEMIC EXPERIENCE</h3>
          <p class="home__info-number">+06</p>
        </div>
        <div>
          <h3 class="home__info-title">PROJECTS DONE</h3>
          <p class="home__info-number">+04</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "HomeSection",
  data() {
    return {
      birthdate: "1984-11-17",
      age: 38,
    };
  },
  mounted() {
    this.calculateAge();
  },
  methods: {
    calculateAge() {
      const birthdate = new Date(this.birthdate);
      const currentDate = new Date();

      const yearsDiff = currentDate.getFullYear() - birthdate.getFullYear();
      const isBeforeBirthday =
        currentDate.getMonth() < birthdate.getMonth() ||
        (currentDate.getMonth() === birthdate.getMonth() &&
          currentDate.getDate() < birthdate.getDate());

      this.age = isBeforeBirthday ? yearsDiff - 1 : yearsDiff;
    },
  },
};
</script>

<style lang="css" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
