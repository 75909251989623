<template>
  <section class="projects section" id="projects">
    <h2 class="section__title">Projects</h2>
    <span class="section__subtitle">Most Recent Works</span>
    <div class="container section__border">
      <swiper
        :cssMode="true"
        :navigation="true"
        :pagination="true"
        :mousewheel="true"
        :keyboard="true"
        :modules="modules"
        :breakpoints="{
          '@0.00': {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          '@0.75': {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          '@1.00': {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          '@1.50': {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }"
        class="mySwiper projects__container"
      >
        <!-- PROJECT 1 -->
        <!-- <swiper-slide>
          <div class="projects__content">
            <img
              src="../assets/img/project1.jpg"
              class="projects__img"
              alt=""
            />
            <div>
              <span class="projects__subtitle">Web</span>
              <h1 class="projects__title">
                ECommerce Store <br />
                Vue/ NodeJS /MongoDB
              </h1>
              <a href="" class="projects__button"
                >View Demo <i class="ri-arrow-right-line"></i
              ></a>
            </div>
          </div>
        </swiper-slide> -->

        <!-- PROJECT 2 -->
        <swiper-slide>
          <div class="projects__content">
            <img
              src="../assets/img/laravel-project.png"
              class="projects__img"
              alt=""
            />
            <div>
              <span class="projects__subtitle">Web </span>
              <h1 class="projects__title">
                Ecommerce Store <br />
                PHP/Laravel
              </h1>
              <a
                href="https://ecommerce.nunomarquesdev.com/"
                class="projects__button"
                >View Demo <i class="ri-arrow-right-line"></i
              ></a>
            </div>
          </div>
        </swiper-slide>

        <!-- PROJECT 3 -->
        <swiper-slide>
          <div class="projects__content">
            <img
              src="../assets/img/mini-trello.png"
              class="projects__img"
              alt=""
            />
            <div>
              <span class="projects__subtitle"> Web </span>
              <h1 class="projects__title">Trello Edition</h1>
              <a
                href="https://trello.nunomarquesdev.com/"
                class="projects__button"
                >View Demo <i class="ri-arrow-right-line"></i
              ></a>
            </div>
          </div>
        </swiper-slide>

        <!-- PROJECT 4 -->
        <swiper-slide>
          <div class="projects__content">
            <img src="../assets/img/netflix.png" class="projects__img" alt="" />
            <div>
              <span class="projects__subtitle"> Web </span>
              <h1 class="projects__title">Netflix Clone Edition</h1>
              <a
                href="https://netflix.nunomarquesdev.com/"
                class="projects__button"
                >Comming Soon <i class="ri-loader-line"></i
              ></a>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";

// import "./style.css";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Mousewheel, Keyboard],
    };
  },
};
</script>
