<template>
  <section class="qualification section" id="qualification">
    <h2 class="section__title">Qualifications</h2>
    <span class="section__subtitle"> Experience & Education </span>
    <div class="qualification__container container grid section__border">
      <!-- QUALFICATION 1 -->
      <div class="qualification__content">
        <h3 class="qualification__title">
          <i class="ri-pencil-ruler-2-line"></i>
          Education
        </h3>
        <div class="qualification__info">
          <div>
            <h3 class="qualification__name">Full Stack Developer</h3>
            <span class="qualification__country">Roeselare - Belgium</span>
            <span class="qualification__year"> 2022-2023</span>
          </div>
          <div>
            <h3 class="qualification__name">The Web Developer Bootcamp</h3>
            <span class="qualification__country">
              Colt Steele, Udemy Course
            </span>
            <span class="qualification__year">2022</span>
          </div>
          <div>
            <h3 class="qualification__name">The Complete Javascript Course</h3>
            <span class="qualification__country">Jonas Schmedtmann</span>
            <span class="qualification__year">2022</span>
          </div>
          <div>
            <h3 class="qualification__name">
              Master's Degree in Physical Education <br />
              Teaching in Badsic and Middle School
            </h3>
            <span class="qualification__country">
              ISMAI - Instituto Superior da Maia - Portugal
            </span>
            <span class="qualification__year">2012-2013</span>
          </div>
          <div>
            <h3 class="qualification__name">
              Degree in Physical Education <br />
              Teaching and Sports
            </h3>
            <span class="qualification__country">
              ISMAI - Instituto Superior da Maia - Portugal
            </span>
            <span class="qualification__year">2003-2008</span>
          </div>
        </div>
      </div>
      <!-- QUALFICATION 2 -->
      <div class="qualification__content">
        <h3 class="qualification__title">
          <i class="ri-building-line"></i>
          Work
        </h3>
        <div class="qualification__info">
          <div>
            <h3 class="qualification__name">Intership Full Stack Developer</h3>
            <span class="qualification__country">
              Tom Broucke, Menen - Belgium
            </span>
            <span class="qualification__year">April & May - 2023</span>
          </div>
          <div>
            <h3 class="qualification__name">Dakwerker</h3>
            <span class="qualification__country">Lauwe - Belgium</span>
            <span class="qualification__year"> 2018 - 2022</span>
          </div>
          <div>
            <h3 class="qualification__name">
              Techinical Director, Fitness Center
            </h3>
            <span class="qualification__country">Luanda - Angola</span>
            <span class="qualification__year">2014 - 2018</span>
          </div>
          <div>
            <h3 class="qualification__name">Teacher</h3>
            <span class="qualification__country">Porto - Portugal</span>
            <span class="qualification__year"> 2008 - 2013</span>
          </div>
        </div>
      </div>
    </div>
    <img
      src="../assets/img/shape-circle.svg"
      alt=""
      class="qualification__img"
    />
  </section>
</template>
<script>
export default {};
</script>
