<template>
  <section class="contact section" id="contact">
    <h2 class="section__title">Contact Me</h2>
    <span class="section__subtitle">Get in touch</span>
    <div class="contact__container container grid section__border">
      <div class="section__content">
        <h3 class="contact__title">
          <i class="ri-chat-3-line">Talk to me</i>
        </h3>
        <div class="contact__info">
          <div class="contact__data">
            <span class="contact__data-title">Email</span>
            <span class="contact__data-info">nunolcmarques@gmail.com</span>
          </div>
          <div class="contact__data">
            <span class="contact__data-title">Whatsaap</span>
            <span class="contact__data-info"> +32 468 43 18 23</span>
            <a
              href="https://wa.me/32468431823"
              target="_blank"
              class="contact__button"
            >
              Write me
              <i class="ri-arrow-right-line"> </i>
            </a>
          </div>
          <!-- <div class="contact__data">
            <span class="contact__data-title">Messenger</span>
            <span class="contact__data-info"> +32 468 43 18 23</span>
            <a href="" class="contact__button"></a>
          </div> -->
        </div>
      </div>
      <div class="contact__content">
        <h3 class="contact__title">
          <i class="ri-send-plane-line">Write me for more informations!</i>
        </h3>
        <form
          ref="form"
          @submit.prevent="sendEmail"
          class="contact__form"
          id="contact-form"
        >
          <div class="contact__form-div">
            <label class="contact__form-tag">Names</label>
            <input
              type="text"
              name="user_name"
              required
              :value="inputFieldReset"
              placeholder="Write your name"
              class="contact__form-input"
              id="contact-name"
            />
          </div>
          <div class="contact__form-div">
            <label for="" class="contact__form-tag">Mail</label>
            <input
              type="email"
              name="user_email"
              required
              :value="inputFieldReset"
              placeholder="Write your email adress"
              class="contact__form-input"
              id="contact-email"
            />
          </div>
          <div class="contact__form-div contact__form-area">
            <label for="" class="contact__form-tag">Message</label>
            <textarea
              type="text"
              name="message"
              required
              :value="inputFieldReset"
              placeholder="Write your message"
              class="contact__form-input"
              id="contact-message"
            />
          </div>
          <p class="contact__message" v-if="hasSend === true">
            Your message was send with success!
          </p>
          <p class="contact__message-error" v-if="notSend">
            Your message was not send!
          </p>
          <button type="submit" class="contact__button-send">
            Send <i class="ri-arrow-right-up-line"></i>
          </button>
        </form>
      </div>
    </div>
  </section>
</template>
//
<script>
import emailjs from "@emailjs/browser";

export default {
  data() {
    return {
      hasSend: false,
      notSend: false,
    };
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_hfj47lr",
          "template_0m9i93f",
          this.$refs.form,
          "rwtDNzh6-btJZqfbQ"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
            this.hasSend = true;
            setTimeout(() => {
              this.hasSend = false;
            }, 5000);
            this.inputFieldReset = " ";
          },
          (error) => {
            console.log("FAILED...", error.text);
            this.notSend = true;
            setTimeout(() => {
              this.notSend = false;
            }, 5000);
          }
        );
    },
  },
};
</script>
