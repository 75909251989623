<template>
  <footer class="footer">
    <div class="footer__container container">
      <h1 class="footer__title">Nuno</h1>
      <p>Full Stack Developer</p>
      <ul class="footer__list">
        <li>
          <a href="#home" class="footer__link">Home</a>
        </li>
        <li>
          <a href="#skills" class="footer__link">Skills</a>
        </li>
        <li>
          <a href="#projects" class="footer__link">Projects</a>
        </li>
      </ul>
      <ul class="footer__social">
        <a
          href="https://www.linkedin.com/in/nuno-lcm/"
          target="_blank"
          class="footer__social-link"
        >
          <i class="ri-linkedin-box-line"></i>
        </a>
        <a
          href="https://github.com/NlcmarquesDev"
          target="_blank"
          class="footer__social-link"
        >
          <i class="ri-github-line"></i>
        </a>
        <a
          href="https://open.spotify.com/playlist/1Brri741BM6JycppajFprj"
          target="_blank"
          class="footer__social-link"
        >
          <i class="ri-spotify-line"></i>
        </a>
      </ul>
      <span class="footer__copy">
        &#169; Design by Bedimcode. Made by Nuno LCM 👋</span
      >
    </div>
  </footer>
  <a
    href="#"
    class="scrollup"
    v-if="showScrollUp"
    :class="{ 'bg-header': boxHeader }"
  >
    <i class="ri-arrow-up-line"></i>
  </a>
</template>
<script>
export default {
  data() {
    return {
      showScrollUp: false,
      boxHeader: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.showScrollUp = window.scrollY > 300;
      // this.boxHeader = header.classList.add("bg-header");
    },
  },
};
</script>
