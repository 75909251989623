<template>
  <main class="main" :class="{ 'dark-theme': darkTheme }">
    <header-top @toggle-theme="toggleTheme" />
    <home-section />
    <skills-section />
    <qualification-section />
    <project-section />
    <contact-section />
    <footer-section />
  </main>
</template>

<script>
import HomeSection from "@/components/HomeSection.vue";
import SkillsSection from "@/components/SkillsSection.vue";
import QualificationSection from "@/components/QualificationSection.vue";
import ProjectSection from "@/components/ProjectSection.vue";
import ContactSection from "@/components/ContactSection.vue";
import FooterSection from "@/components/FooterSection.vue";
import HeaderTop from "@/components/HeaderTop.vue";
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {
    HomeSection,
    SkillsSection,
    QualificationSection,
    ProjectSection,
    ContactSection,
    FooterSection,
    HeaderTop,
  },
  data() {
    return {
      darkTheme: false,
    };
  },
  methods: {
    toggleTheme() {
      this.darkTheme = !this.darkTheme;
    },
  },
};
</script>
